import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Cover from "../components/sections/WorkWithUsPageSections/Cover/Cover";
import JobOpenings from "../components/sections/WorkWithUsPageSections/JobOpenings/JobOpenings";
import WorkWithUsGallery from "../components/sections/WorkWithUsPageSections/WorkWithUsGallery/WorkWithUsGallery";
import { Helmet } from "react-helmet";

const WorkWithUs = () => {
  return (
    <>
      <Helmet>
        <title>Work with us</title>
        <meta
          name="description"
          content="endshift is a solution of the last resort. It helps enterprises, small to large businesses, and startups to scale and remove any technological congestion."
        />
        <link rel="canonical" href="https://endshift.io/" />
      </Helmet>

      <Header dataColor="header-dark" />
      <main>
        <Cover />
        <WorkWithUsGallery />
        <JobOpenings />
      </main>
      <Footer />
    </>
  );
};

export default WorkWithUs;
