import React, { useLayoutEffect, useRef } from "react";
import * as CoverStyles from "./Cover.module.scss";
import CoverImg from "../../../../assets/images/how-we-work-cover.webp";
import { FadeInUPTextAnimation } from "../../../../../GSAPSnippets";
import { StaticImage } from "gatsby-plugin-image";

const Cover = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimation("WWUText-wrapper", 1.5);
  }, []);

  return (
    <section className={`es-section__light ${CoverStyles.CoverWrapper}`}>
      <div className="WWUText-wrapper">
        <span className="es-subtitle-text">Career at endshift</span>
        <h2>
          We believe that an inspired life produces inspired work. At endshift,
          you are given an opportunity to make a difference from day one.
        </h2>
        <div className={CoverStyles.CoverImgWrapper}>
          <StaticImage
            src="../../../../assets/images/how-we-work-cover.webp"
            alt="work with us"
            layout="fullWidth"
            placeholder="none"
          />
        </div>
      </div>
    </section>
  );
};

export default Cover;
