import React, { useEffect, useLayoutEffect, useRef } from "react";
import * as WorkWithUsGalleryStyles from "./WorkWithUsGallery.module.scss";
import wwu1 from "../../../../assets/images/wwu-1.png";
import wwu2 from "../../../../assets/images/wwu-2.png";
import wwu3 from "../../../../assets/images/wwu-3.png";
import wwu4 from "../../../../assets/images/wwu-4.png";

import { FadeInUPTextAnimationWithTrigger } from "../../../../../GSAPSnippets";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const WWUGalleryData = [
  {
    img: wwu1,
  },
  {
    img: wwu2,
  },
  {
    img: wwu3,
  },
  {
    img: wwu4,
  },
];

const WorkWithUsGallery = () => {
  const WWUGSectionRef = useRef();

  useEffect(() => {
    FadeInUPTextAnimationWithTrigger("WWUGText-wrapper", "WWUGSection");
  }, []);

  return (
    <section
      ref={WWUGSectionRef}
      className={`es-section__dark WWUGSection ${WorkWithUsGalleryStyles.WorkWithUsGalleryWrapper}`}
    >
      <div className="WWUGText-wrapper">
        <p>
          We believe that work should challenge and inspire you. It should fill
          you with a sense of purpose. The kind you feel in your heart when
          you’re a part of something bigger—something that's making a difference
          in the world.
        </p>

        <div className={WorkWithUsGalleryStyles.WorkWithUsGallery}>
          {WWUGalleryData.map((item, index) => (
            <div
              className={WorkWithUsGalleryStyles.WorkWithUsGalleryImg}
              key={index}
            >
              <img
                loading="lazy"
                className="WWUGImg"
                src={item.img}
                alt="work with us gallery"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkWithUsGallery;
