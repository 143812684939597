import React, { useLayoutEffect, useRef } from "react";
import { Link } from "gatsby";
import OurVisionVectorLg from "../../../OurVisionVector/OurVisionVectorLg";
import * as JobOpeningsStyles from "./JobOpenings.module.scss";

import { FadeInUPTextAnimationWithTrigger } from "../../../../../GSAPSnippets";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const JobOpeningsData = [
  {
    title: "Medior Java Developer",
    duration: "Full time",
    location: "Lahore",
    link: "/",
  },
  {
    title: "Medior Python Developer",
    duration: "Full time",
    location: "Lahore",
    link: "/",
  },
  {
    title: "Medior React.js Developer",
    duration: "Full time",
    location: "Lahore",
    link: "/",
  },
  {
    title: "Open Application",
    duration: "Full time",
    location: "Lahore",
    link: "/",
  },
];
const JobOpenings = () => {
  const JobOpeningsSectionRef = useRef();

  useLayoutEffect(() => {
    FadeInUPTextAnimationWithTrigger("JOText", "JOSection");
  }, []);
  return (
    <section
      ref={JobOpeningsSectionRef}
      className={`es-section__light JOSection ${JobOpeningsStyles.JobOpeningsWrapper}`}
    >
      <div className={`JOText ${JobOpeningsStyles.JobOpeningsGrid}`}>
        <div>
          <span className="es-subtitle-text">Full-time opportunity</span>
          <p>
            if you think you might be a good fit for our team, we’d love to hear
            from you! If you don’t find a suitable position, you can still send
            us an e-mail to
            <a href="mailto:careers@endshift.io">careers@endshift.io </a>
            with your experience or portfolio and why you’d like to collaborate.
          </p>
        </div>
        <div className={JobOpeningsStyles.JobOpeningsVector}>
          <OurVisionVectorLg />
        </div>

        {/* <div className={JobOpeningsStyles.JobOpeningsListing}>
          {JobOpeningsData.map((item, index) => (
            <div
              key={index}
              className={`JOText ${JobOpeningsStyles.JobOpeningsListingItem}`}
            >
              <Link to={item.link}>
                <h3>{item.title}</h3>
                <span>
                  {item.duration} | {item.location}
                </span>
              </Link>
            </div>
          ))}
        </div> */}
      </div>
    </section>
  );
};

export default JobOpenings;
